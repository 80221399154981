<template>
  <div class="extration-bdd-frais">
    <b-button
      v-if="
        cantCreate(interfaceName) &&
          filterType &&
          computedCheckedRowsAvoir.length
      "
      size="sm"
      class="button-export-style  ml-2"
      @click="showConfirmAlert"
      title="Modifier les statuts"
    >
      <font-awesome-icon icon="check-double"
    /></b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filterType: {
      default: null
    },
    dataToUse: { required: true },
    curentInterface: { default: 'frais' },
    interfaceName: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false,
      tableToUse: []
    };
  },
  methods: {
    ...mapActions(['validateStatueInstallateurGlobale', 'avoirFraisFcatureTh']),
    resetModal() {
      this.error = null;
      this.loading = false;
    },
    showConfirmAlert() {
      let table = this.dataToUse.filter(row => {
        return row.projects.some(l => l.check == true);
      });

      let htmlMessage =
        "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
      this.tableToUse = [];
      for (let i = 0; i < table.length; i++) {
        for (let j = 0; j < table[i].projects.length; j++) {
          if (
            table[i].projects[j].check == true &&
            table[i].projects[j][this.computedColumn] == 'annulee'
          ) {
            this.tableToUse.push(table[i].projects[j]);
            htmlMessage =
              htmlMessage +
              '<li>' +
              table[i].projects[j].numero_dossier +
              '</li>';
          }
        }
      }

      htmlMessage = htmlMessage + '</div></div>';
      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title:
            'Êtes-vous sur de vouloir créer une avoir pour chacun de ces dossiers',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          html: htmlMessage,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            for (let i = 0; i < this.tableToUse.length; i++) {
              bodyFormData.append(
                'ids[' + [i] + ']',
                this.tableToUse[i][this.computedFields]
              );
            }
            const response = await this.avoirFraisFcatureTh(bodyFormData);
            if (response) {
              let text =
                '<div style="dispaly:flex"><div>Avoir creé avec succès  : ' +
                response.data.success +
                '\n' +
                '\n' +
                '<div>Avoir non crée : ' +
                response.data.error +
                '</div>';
              this.$alert('', text, 'success').then(() => {
                this.$emit('reloadData');
              });
            }
          }
        });
    }
  },
  computed: {
    ...mapGetters(['getUserData', 'cantCreate']),
    computedCheckedRowsAvoir() {
      return this.dataToUse?.filter(k => {
        return k.projects.some(
          e => e.check == true && e[this.computedColumn] == 'annulee'
        );
      });
    },
    computedFields() {
      switch (this.filterType) {
        case 'NOVA':
          return 'operationnel_id';
        case 'CACTUS':
          return this.curentInterface && this.curentInterface == 'frais'
            ? 'commercial_id'
            : 'cactus_id';
        case 'AXELIUS':
          return 'marketing_id';
        case 'ISO 33':
          return 'technique_id';
        case 'CERTILIA':
          return 'rge_id';
        case 'GE CDG':
          return 'CDG_id';
        case 'SAMI-B':
          return 'administratif_sav_id';
        case 'WEB ABONDANCE':
          return 'dsi_id';
        case 'CORPEDYS':
          return 'juridique_id';
        case 'ALTEO France':
          return 'alger_id';
        case 'NOVAE MAIRIE':
          return 'mairie_id';
        case 'GSE':
          return 'maintenance_pac_id';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube_id';
        case 'LAV':
          return 'externe_id';
        case 'ALTEO FRANCE Régie Commerciale INTERNE':
          return 'interne_id';
        case 'ALTEO FRANCE Supervision':
          return 'alteo_id';
        case 'ALTEO FRANCE Support Commercial':
          return 'commercial_id';
        case 'BE B KYNAN':
          return 'facture_kynan_id';
        case 'BE B ALTEO':
          return 'facture_alteo_id';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'facture_id';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube_id';
        case 'IHS':
          return 'ihs_id';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion_id';
        default:
          return this.filterType;
      }
    },
    computedColumn() {
      switch (this.filterType) {
        case 'NOVA':
          return 'statue_operationnel';
        case 'CACTUS':
          return this.curentInterface && this.curentInterface == 'frais'
            ? 'statue_commercial'
            : 'cactus_statue';
        case 'AXELIUS':
          return 'statue_marketing';
        case 'ISO 33':
          return 'statue_technique';
        case 'CERTILIA':
          return 'statue_rge';
        case 'GE CDG':
          return 'statue_CDG';
        case 'SAMI-B':
          return 'statue_administratif_sav';
        case 'WEB ABONDANCE':
          return 'statue_dsi';
        case 'CORPEDYS':
          return 'statue_juridique';
        case 'ALTEO France':
          return 'statue_alger';
        case 'NOVAE MAIRIE':
          return 'statue_mairie';
        case 'GSE':
          return 'statue_maintenance_pac';
        case 'UPCUBE TECHNOLOGIES':
          return 'statue_upcube';
        case 'LAV':
          return 'externe_statue';
        case 'ALTEO FRANCE Régie Commerciale INTERNE':
          return 'interne_statue';
        case 'ALTEO FRANCE Supervision':
          return 'alteo_statue';
        case 'ALTEO FRANCE Support Commercial':
          return 'commercial_statue';
        case 'BE B KYNAN':
          return 'statue_kynan';
        case 'BE B ALTEO':
          return 'statue_alteo';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'statue';
        case 'UPCUBE TECHNOLOGIES':
          return 'statue_upcube';
        case 'IHS':
          return 'statue_ihs';
        case 'Alteo contrôle qualité':
          return 'statue_alteo_control_gestion';
        default:
          return this.filterType;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
